import React from "react"
import { Routes } from "../../data/routes"
import { PrimaryButton, SecondaryButton } from "../../components/button"
import Card from "../../components/card"
import CTA from "../../components/CTA"
import Img from "../../components/img"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Content = {
  pageName: "Purchase Complete",
  description: "You're all set",
  header: "You're all set",
  headerImage: "purchase-complete.svg",
  subheader: "Ready for your tutor?",
  headerCTAs: [
    {
      action: "Web",
      link: "https://student.yup.com/",
    },
    {
      action: "iPhone",
      link: "https://apps.apple.com/us/app/yup-math-tutoring-app/id839897809",
    },
    {
      action: "Android",
      link: "https://play.google.com/store/apps/details?id=com.mathcrunch.mathcrunch.app",
    },
  ],

  // Resources section
  resources: [
    {
      name: "Getting started with Yup",
      image: "purchase-complete-resource-1.png",
      link: "https://youtu.be/Mlr4tflgPo8",
    },
    {
      name: "Success stories",
      image: "purchase-complete-resource-2.png",
      link: `${Routes.downloads}FamilySpotlight.pdf`,
    },
    {
      name: "Help",
      image: "purchase-complete-resource-3.png",
      link: "https://helpcenter.yup.com/",
    },
  ],

  // CTA section
  cta: "See your child's progress",
  ctaActions: [
    {
      text: "Visit the family portal",
      link: "https://family.yup.com/",
    },
    {
      text: "Learn more",
      link: "https://helpcenter.yup.com/hc/en-us/articles/360047772973-How-do-I-sign-up-and-log-into-the-Family-Portal-",
    },
  ],
}

function Header() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-b731164a9948a780a5a346d9e637b053
  return (
    <div className="flex flex-col-reverse text-center md:text-left md:flex-row justify-around items-center mx-auto max-w-7xl">
      <div className="py-8">
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl">
          {Content.header}
        </h1>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
          {Content.subheader}
        </p>
        <div className="mt-2 flex flex-col sm:flex-row sm:justify-center lg:justify-start">
          {Content.headerCTAs.map((action, index) => (
            <div className="flex-1 p-2" key={action.action}>
              {index === 0 ? (
                <PrimaryButton
                  text={action.action}
                  href={action.link}
                  className="w-full"
                />
              ) : (
                <SecondaryButton
                  text={action.action}
                  href={action.link}
                  className="w-full"
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="p-4">
        <Img
          className="sm:max-w-xl md:max-w-5xl lg:max-w-3xl w-full"
          src={Content.headerImage}
          cover={true}
        />
      </div>
    </div>
  )
}

function ResourceSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/team-sections#component-3e122aee81a11936f182380439051844

  return (
    <div className="mx-auto pt-12 px-4 max-w-7xl sm:px-6 lg:px-12">
      <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
        <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
          Get the most out of Yup
        </h2>
        <p className="text-xl text-gray-500">
          Onboarding materials from our Resource Library
        </p>
      </div>
      <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3">
        {Content.resources.map(resource => (
          <li key={resource.name}>
            <a href={resource.link} target="_blank">
              <Card className="space-y-4">
                <div className="aspect-w-3 aspect-h-2">
                  <Img
                    className="object-cover shadow-lg rounded-lg"
                    src={resource.image}
                    alt={resource.name}
                    cover={true}
                  />
                </div>

                <div className="space-y-2">
                  <div className="text-lg leading-6 font-medium space-y-1">
                    <h3>{resource.name}</h3>
                  </div>
                </div>
              </Card>
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

const PurchaseComplete = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.purchaseComplete}
      />
      <Header />
      <ResourceSection />
      <CTA title={Content.cta} actions={Content.ctaActions} />
    </Layout>
  )
}

export default PurchaseComplete
